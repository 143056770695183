








































































































import { Vue, Component } from 'vue-property-decorator';
import { SelectItemOption } from '@/classes/base/elements';
import { MeetingStore } from '@/store';
import { WorkingPeriodResource, MeetingConfig } from '@/classes/WorkingPeriod.resource';
import { downloadMeetConfigAsJson, cleanLocalStorage } from '../helper/index';
@Component({
	components: {
		BaseDialog: () => import('../components/base/BaseDialog.vue'),
		BaseButton: () => import('../components/base/BaseButton.vue'),
		FileInput: async () => import('../components/FileInput.vue')
	}
})
export default class Navbar extends Vue {
	public displayMobileMenu = false;
	public displaySettingsMenu = false;
	public fileInputDialog = false;
	public cleanStoreDialog = false;

	async action(option: string): Promise<void> {
		if (option === 'clean-store') this.cleanStoreDialog = true;
		if (option === 'save') this.downloadObjectAsJson();
		if (option === 'import' || option === 'merge') this.fileInputDialog = true;
	}

	async downloadObjectAsJson(): Promise<void> {
		downloadMeetConfigAsJson(MeetingStore.workingPeriods, MeetingStore.globalId, MeetingStore.clientId);
		this.displayMobileMenu = false;
	}

	async cleanStore(): Promise<void> {
		cleanLocalStorage();
		this.reload();
	}

	async reload(): Promise<void> {
		location.reload();
	}

	async openPanel(): Promise<void> {
		if (this.displaySettingsMenu === false) {
			this.displaySettingsMenu = true;
			setTimeout(() => {
				const list = document.getElementById('listbox');
				if (list) {
					list.focus();
				}
			}, 1);
		} else if (this.displaySettingsMenu === true) {
			this.displaySettingsMenu = false;
		}
	}

	async closePanel(): Promise<void> {
		setTimeout(() => {
			this.displaySettingsMenu = false;
		}, 110);
	}

	get settingsOptions(): SelectItemOption[] {
		const options: SelectItemOption[] = [];
		if (localStorage.getItem('MeetingTimer-MeetingsConfig')) {
			options.push({
				name: 'clean-store',
				description: 'Delete all data',
				icon: {
					name: 'database-remove-outline'
				}
			});
		}
		if (MeetingStore.workingPeriods.length === 0) {
			options.push({
				name: 'import',
				description: 'Import',
				icon: {
					name: 'import'
				}
			});
		}
		if (MeetingStore.workingPeriods.length > 0) {
			options.push(
				{
					name: 'merge',
					description: 'Import',
					icon: {
						name: 'import'
					}
				},
				{
					name: 'save',
					description: 'Save progress',
					icon: {
						name: 'tray-arrow-down'
					}
				}
			);
		}
		return options;
	}

	get appVersion(): string {
		return MeetingStore.appVersion;
	}

	mergeData(newMeetsObject: MeetingConfig): void {
		if (newMeetsObject && newMeetsObject.workingPeriods) {
			const newMeetings: WorkingPeriodResource[] = [...newMeetsObject.workingPeriods];
			const currentMeetings: WorkingPeriodResource[] = [...MeetingStore.workingPeriods];
			const meetsToAdd: WorkingPeriodResource[] = [];
			newMeetings.forEach((newMeet: WorkingPeriodResource) => {
				if (this.meetNotEqualToOneInOldArray(newMeet)) {
					meetsToAdd.push(newMeet);
				}
			});
			if (meetsToAdd.length > 0) {
				currentMeetings.push(...meetsToAdd);
				let newId = 1;
				currentMeetings.forEach((meet) => {
					meet.id = newId;
					newId++;
				});
				localStorage.setItem('MeetingTimer-MeetingsConfig', JSON.stringify(currentMeetings));
				localStorage.setItem('MeetingTimer-GlobalId', JSON.stringify(newId));
			}
			this.reload();
		}
	}

	meetNotEqualToOneInOldArray(newMeet: WorkingPeriodResource): boolean {
		if (MeetingStore.workingPeriods && MeetingStore.workingPeriods.length > 0) {
			let isCopyOfOne = false;
			MeetingStore.workingPeriods.forEach((currentMeet) => {
				if (JSON.stringify(currentMeet) === JSON.stringify(newMeet) /* compareMeets(oldMeet, newMeet) => boolean : equal oder not equal*/) {
					isCopyOfOne = true;
				}
			});
			if (!isCopyOfOne) {
				return true;
			}
			return false;
		}
		return false;
	}

	get configEmpty(): boolean {
		return MeetingStore.workingPeriods.length > 0 ? false : true;
	}
}
